<template>
  <div>
    <v-card data-testid="container-speed-tests">
      <v-toolbar>
        <v-card-title data-testid="title-speed-tests">Speed tests</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            $can('create', 'debug_speed_test') || $can('create', 'Superadmin')
          "
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          :to="`/engines/speed-test/create`"
          data-testid="btn-create-speed-test"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="table"
          :headers="headers"
          :items="tests"
          :server-items-length="testsCount"
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-speed-tests"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="pa-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span data-testid="speed-test-name">
              {{ item.name }}
            </span>
          </template>

          <template v-slot:[`item.total_results`]="{ item }">
            <div v-if="item.total_results > 0">
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                :color="Pallette.actionButtons.play"
                @click="showTestHistory(item)"
                class="clear-padding"
                data-testid="btn-history-speed-test"
              >
                History
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </div>
          </template>
          <template
            v-slot:[`item.test`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <v-btn
              :color="Pallette.actionButtons.bigPlay"
              dark
              :to="`/engines/speed-test/run/${item._key}`"
              v-if="
                $can('create', 'debug_speed_test') ||
                $can('create', 'Superadmin')
              "
              class="btn-start-view-test"
              data-testid="btn-start-speed-test"
            >
              Start test
              <v-icon dark right>mdi-chart-areaspline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                  data-testid="btn-speed-test-actions-more"
                  v-if="
                    $can('update', 'debug_speed_test') ||
                    $can('delete', 'debug_speed_test') ||
                    $can('update', 'Superadmin') ||
                    $can('delete', 'Superadmin') ||
                    $can('create', 'debug_speed_test_set_perm_link') ||
                    $can('create', 'Superadmin')
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  :to="`/engines/speed-test/edit/${item._key}`"
                  v-if="
                    $can('update', 'debug_speed_test') ||
                    $can('update', 'Superadmin')
                  "
                  data-testid="btn-edit-speed-test"
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit test</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit"
                      >mdi-pencil</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="generateLinkDialog(item)"
                  v-if="
                    $can('create', 'debug_speed_test_set_perm_link') ||
                    $can('create', 'Superadmin')
                  "
                  data-testid="btn-generate-link-speed-test"
                >
                  <v-list-item-content>
                    <v-list-item-title>Generate link</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.generate"
                      >mdi-link-variant</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deleteTest(item)"
                  v-if="
                    $can('delete', 'debug_speed_test') ||
                    $can('delete', 'Superadmin')
                  "
                  data-testid="btn-delete-speed-test"
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete speed test</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="!$vuetify.breakpoint.smAndUp"
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="showTestHistory(item)"
                  v-if="item.total_results > 0"
                  icon="mdi-history"
                  text="Test History"
                  :color="Pallette.actionButtons.play"
                  data-testid="btn-histore-speed-test-sm"
                />
                <VerticalButton
                  :route="`/engines/speed-test/edit/${item._key}`"
                  v-if="
                    $can('update', 'debug_speed_test') ||
                    $can('update', 'Superadmin')
                  "
                  icon="mdi-pencil"
                  text="Edit test"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-speed-test-sm"
                />
                <VerticalButton
                  @click.native="generateLinkDialog(item)"
                  v-if="
                    $can('create', 'debug_speed_test_set_perm_link') ||
                    $can('create', 'Superadmin')
                  "
                  icon="mdi-link-variant"
                  text="Generate link"
                  :color="Pallette.actionButtons.generate"
                  data-testid="btn-generate-link-speed-test-sm"
                />
                <VerticalButton
                  @click.native="deleteTest(item)"
                  v-if="
                    $can('delete', 'debug_speed_test') ||
                    $can('delete', 'Superadmin')
                  "
                  icon="mdi-delete"
                  text="Delete test"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-speed-test-sm"
                />
                <VerticalButton
                  :route="`/engines/speed-test/run/${item._key}`"
                  v-if="
                    $can('delete', 'debug_speed_test') ||
                    $can('delete', 'Superadmin')
                  "
                  icon="mdi-chart-areaspline"
                  text="Start test"
                  :color="Pallette.actionButtons.bigPlay"
                  data-testid="btn-start-speed-test-sm"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <generate-link-dialog
      v-if="dialogLink"
      :dialog.sync="dialogLink"
      :editedIndex.sync="editedIndex"
    />
    <confirm ref="confirmDialog"></confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import publicIp from "public-ip"
import GeneralMixin from "@/mixins/general.js"
import STCommonMixin from "@/mixins/speed-test/common"
import GenerateLinkDialog from "./GenerateLinkDialog"
import Confirm from "@/components/Confirm"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    GenerateLinkDialog,
    Confirm,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [GeneralMixin, STCommonMixin],

  data: () => ({
    dialogLink: false,
    editedIndex: -1,
    headers: [
      {
        text: "Name",
        value: "name",
        align: "left",
        sortable: true,
      },
      {
        name: "",
        value: "total_results",
        align: "left",
        sortable: false,
        width: 60,
      },
      {
        text: "",
        value: "test",
        align: "center",
        sortable: false,
        class: "th-speed-test",
        width: 120,
      },
      {
        text: "",
        value: "menu",
        align: "center",
        sortable: false,
        class: "th-clear-padding",
        width: 50,
      },
    ],
    tests: [],
    testsCount: 0,
    filterSearch: "",
  }),

  created: function () {
    let self = this
    self.getCompanyInfo()
    publicIp.v4().then((ip) => {
      self.$store.dispatch("saveCurrentIpAction", ip)
    })
  },

  watch: {
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    fetchTableData: function () {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      let getParams = self.$refs.table.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch

      self.$store
        .dispatch("speedTestGetListAction", { params: getParams })
        .then(function (response) {
          self.tests = response?.data?.data?.result || []
          self.testsCount = response?.data?.data?.full_count || 0
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },
    updateCreatedData(key) {
      let self = this
      self.editedIndex = key
      self.getItemData()
    },
    deleteTest(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this Speed Test?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store.dispatch("saveTableLoaderAction", true)
            self.$store
              .dispatch("speedTestDeleteAction", item)
              .then(({ data }) => {
                self.fetchTableData()
                self.$store.dispatch("saveTableLoaderAction", false)
              })
          }
        })
    },
    getCompanyInfo: function () {
      let self = this
      self.$store.dispatch("currentUserCompanyInfo")
    },
    generateLinkDialog(item) {
      let self = this
      self.editedIndex = item._key
      self.getItemData().then(() => {
        this.dialogLink = true
      })
    },
    showTestHistory(item) {
      this.$router
        .push({ path: `/engines/speed-test/history/${item._key}` })
        .catch((err) => {})
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
  },

  beforeRouteLeave(to, from, next) {
    if (this.dialogLink) {
      this.dialogLink = false
      this.editedIndex = -1
      next(false)
    } else {
      this.dialogLink = false
      this.editedIndex = -1
      next()
    }
  },
}
</script>

<style lang="scss">
.btn-start-view-test {
  width: 160px;
}
</style>
