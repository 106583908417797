<template>
  <v-dialog
    :value="dialog"
    persistent
    max-width="600px"
    dark
    content-class="dialog-speed-test-generate-link"
  >
    <v-form
      @submit.prevent
      data-vv-scope="form-speed-test-generate-link"
      data-testid="form-speed-test-generate-link"
    >
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title
            class="white--text"
            data-testid="title-speed-test-generate-link"
          >
            Generate link for test
            <span
              class="blue-grey--text text--lighten-4"
              data-testid="title-speed-test-name"
            >
              {{ activeSpeedTest.name }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 v-if="!isLinkGenerated">
                <v-text-field
                  outlined
                  v-model.trim="email"
                  label="Link"
                  type="text"
                  style="align-items: baseline"
                  :error-messages="
                    errors.collect('form-speed-test-generate-link.email')
                  "
                  v-validate="'required'"
                  data-testid="email"
                >
                  <!-- <template v-slot:append-outer>
                    <v-btn @click="generateLink" x-large color="primary">
                      <v-icon left>mdi-link-variant</v-icon>
                      Generate link
                    </v-btn>
                  </template> -->
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm12 v-if="isLinkGenerated">
                <v-text-field
                  outlined
                  v-model="link"
                  label="Link"
                  type="text"
                  style="align-items: baseline"
                  id="speed-test-generated-link"
                  :error-messages="
                    errors.collect('form-speed-test-generate-link.link')
                  "
                  data-testid="link"
                >
                  <!-- <template v-slot:append-outer>
                    <v-btn @click="copyLink" color="primary">
                      <v-icon left>mdi-content-copy</v-icon>
                      Copy Link
                    </v-btn>
                  </template> -->
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm12> </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="$emit('update:dialog', false)"
            data-testid="btn-close"
            >Close</v-btn
          >
          <v-btn
            v-if="isLinkGenerated"
            @click="copyLink"
            text
            color="primary"
            data-testid="btn-copy-link"
          >
            <v-icon left>mdi-content-copy</v-icon>
            Copy Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import GeneralMixin from "@/mixins/general.js"

export default {
  name: "speed-test-generate-link",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  data() {
    return {
      email: "",
      link: null,
    }
  },

  mixins: [GeneralMixin],

  mounted() {
    this.generateLink()
  },

  beforeDestroy() {
    this.email = ""
    this.link = null
  },

  methods: {
    copyLink() {
      let self = this
      self.selectLinkText()
      navigator.clipboard.writeText(this.link).then(
        function () {
          let params = {
            text: "Speed test link was copied successful!",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        },
        function (err) {
          let params = {
            text: "Speed test link was copied without success!",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        }
      )
    },
    generateLink() {
      let self = this
      this.$store
        .dispatch("speedTestGenerateLinkAction", {
          email: this.email,
          room_key: this.editedIndex,
        })
        .then((response) => {
          this.link =
            window.location.origin +
            "/engines/speed-test/" +
            response.data.data.link_hash
          setTimeout(() => {
            self.selectLinkText()
          }, 20)
        })
        .catch((error) => {
          this.handleValidationErrors(
            error.response,
            "form-speed-test-generate-link"
          )
        })
    },

    selectLinkText() {
      const input = document.getElementById("speed-test-generated-link")
      input.select()
    },
  },
  computed: {
    ...mapGetters({
      activeSpeedTest: "getActiveSpeedTest",
    }),

    isLinkGenerated: function () {
      return !_.isNull(this.link)
    },
  },
}
</script>
