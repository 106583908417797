var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"data-testid":"container-speed-tests"}},[_c('v-toolbar',[_c('v-card-title',{attrs:{"data-testid":"title-speed-tests"}},[_vm._v("Speed tests")]),_c('v-spacer'),(
          _vm.$can('create', 'debug_speed_test') || _vm.$can('create', 'Superadmin')
        )?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp,"to":`/engines/speed-test/create`,"data-testid":"btn-create-speed-test"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('DataTableExtended',{ref:"table",staticClass:"elevation-0 border",attrs:{"headers":_vm.headers,"items":_vm.tests,"server-items-length":_vm.testsCount,"loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"customSearch":_vm.filterSearch,"data-testid":"table-speed-tests"},on:{"init-table-data":_vm.fetchTableData,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function({ options, updateOptions }){return [_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''),attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","data-testid":"filter-search"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('SortMobile',{attrs:{"headers":_vm.headers,"options":options},on:{"update:options":updateOptions}}):_vm._e()]}},{key:`item.name`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"speed-test-name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.total_results`,fn:function({ item }){return [(item.total_results > 0)?_c('div',[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',{staticClass:"clear-padding",attrs:{"color":_vm.Pallette.actionButtons.play,"data-testid":"btn-history-speed-test"},on:{"click":function($event){return _vm.showTestHistory(item)}}},[_vm._v(" History "),_c('v-icon',[_vm._v("mdi-history")])],1):_vm._e()],1):_vm._e()]}},(_vm.$vuetify.breakpoint.smAndUp)?{key:`item.test`,fn:function({ item }){return [(
              _vm.$can('create', 'debug_speed_test') ||
              _vm.$can('create', 'Superadmin')
            )?_c('v-btn',{staticClass:"btn-start-view-test",attrs:{"color":_vm.Pallette.actionButtons.bigPlay,"dark":"","to":`/engines/speed-test/run/${item._key}`,"data-testid":"btn-start-speed-test"}},[_vm._v(" Start test "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-chart-areaspline")])],1):_vm._e()]}}:null,{key:`item.menu`,fn:function({ item }){return [_c('v-menu',{attrs:{"transition":"slide-y-transition","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                  _vm.$can('update', 'debug_speed_test') ||
                  _vm.$can('delete', 'debug_speed_test') ||
                  _vm.$can('update', 'Superadmin') ||
                  _vm.$can('delete', 'Superadmin') ||
                  _vm.$can('create', 'debug_speed_test_set_perm_link') ||
                  _vm.$can('create', 'Superadmin')
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"clear-padding",attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":_vm.Pallette.actionButtons.menu,"data-testid":"btn-speed-test-actions-more"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(
                  _vm.$can('update', 'debug_speed_test') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('v-list-item',{attrs:{"to":`/engines/speed-test/edit/${item._key}`,"data-testid":"btn-edit-speed-test"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit test")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.edit}},[_vm._v("mdi-pencil")])],1)],1):_vm._e(),(
                  _vm.$can('create', 'debug_speed_test_set_perm_link') ||
                  _vm.$can('create', 'Superadmin')
                )?_c('v-list-item',{attrs:{"data-testid":"btn-generate-link-speed-test"},on:{"click":function($event){return _vm.generateLinkDialog(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Generate link")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.generate}},[_vm._v("mdi-link-variant")])],1)],1):_vm._e(),(
                  _vm.$can('delete', 'debug_speed_test') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('v-list-item',{attrs:{"data-testid":"btn-delete-speed-test"},on:{"click":function($event){return _vm.deleteTest(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete speed test")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.delete}},[_vm._v(" mdi-delete")])],1)],1):_vm._e()],1)],1)]}},(!_vm.$vuetify.breakpoint.smAndUp)?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[(item.total_results > 0)?_c('VerticalButton',{attrs:{"icon":"mdi-history","text":"Test History","color":_vm.Pallette.actionButtons.play,"data-testid":"btn-histore-speed-test-sm"},nativeOn:{"click":function($event){return _vm.showTestHistory(item)}}}):_vm._e(),(
                  _vm.$can('update', 'debug_speed_test') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"route":`/engines/speed-test/edit/${item._key}`,"icon":"mdi-pencil","text":"Edit test","color":_vm.Pallette.actionButtons.edit,"data-testid":"btn-edit-speed-test-sm"}}):_vm._e(),(
                  _vm.$can('create', 'debug_speed_test_set_perm_link') ||
                  _vm.$can('create', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-link-variant","text":"Generate link","color":_vm.Pallette.actionButtons.generate,"data-testid":"btn-generate-link-speed-test-sm"},nativeOn:{"click":function($event){return _vm.generateLinkDialog(item)}}}):_vm._e(),(
                  _vm.$can('delete', 'debug_speed_test') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-delete","text":"Delete test","color":_vm.Pallette.actionButtons.delete,"data-testid":"btn-delete-speed-test-sm"},nativeOn:{"click":function($event){return _vm.deleteTest(item)}}}):_vm._e(),(
                  _vm.$can('delete', 'debug_speed_test') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"route":`/engines/speed-test/run/${item._key}`,"icon":"mdi-chart-areaspline","text":"Start test","color":_vm.Pallette.actionButtons.bigPlay,"data-testid":"btn-start-speed-test-sm"}}):_vm._e()],1)],1)]}}:null],null,true)})],1)],1),(_vm.dialogLink)?_c('generate-link-dialog',{attrs:{"dialog":_vm.dialogLink,"editedIndex":_vm.editedIndex},on:{"update:dialog":function($event){_vm.dialogLink=$event},"update:editedIndex":function($event){_vm.editedIndex=$event},"update:edited-index":function($event){_vm.editedIndex=$event}}}):_vm._e(),_c('confirm',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }